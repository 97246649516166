import React, {useEffect, useState} from "react";
import {
    Layout,
    Breadcrumb,
    Row,
    Col,
    Table, Tag, Button
} from 'antd';
import Api from "../api.js";
import {Link, useParams} from "react-router-dom"
import CreateCompetition from "../components/create-competition";
import moment from "moment/moment";
import EditTrainingVideoCategories from "../components/edit-training-video-categories";

const {Content} = Layout;

const ChallengesReportView = () => {
    const params = useParams();
    const [competition, setCompetition] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        getCompetitions();
    }, [])

    const getCompetitions = () => {
        setLoading(true);

        Api.get('/admin/competitions/' + params.id + '/participants').then(res => {
            setCompetition(res.data);

            console.log(res.data);

            setLoading(false);
        })
    }

    const removeParticipant = (id) => {
        Api.delete('/admin/competitions/' + params.id + '/participants/' + id).then(res => {
            getCompetitions()
        })
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 60,
        },
        {
            title: 'user_id',
            dataIndex: 'participatable_id',
            key: 'participatable_id',
            width: 100,
            render: (value, record) => (<Link to={"/user/" + value}>{value}</Link>)
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            render: (value, record) => (record.user ? record.user.email : '')
        },
        {
            title: 'Steps count',
            dataIndex: 'total_steps',
            key: 'total_steps',
            render: (value, record) => (record.user ? record.user.steps_count : '')
        },
        {
            title: 'action',
            dataIndex: 'action',
            key: 'action',
            render: (value, record) => <Button size="small" onClick={() => removeParticipant(record.id)} danger={true}>Remove</Button>
        },
    ]

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Reports</Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/challenges-report">Challenges</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{params.id}</Breadcrumb.Item>
            </Breadcrumb>


                <Col span={24}>
                    <div className="site-layout-card">

                        <Row type="flex" justify="space-between" style={{marginBottom: 20}}>
                            <h1 style={{margin: 0}}>{competition.name}</h1>

                            <CreateCompetition callback={getCompetitions}/>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <Table loading={loading} style={{width: "100%"}} pagination={false} columns={columns} dataSource={competition.sorted_participants}/>
                            </Col>
                        </Row>
                    </div>
                </Col>
        </Content>
    );
}

export default ChallengesReportView;