import React, {useEffect, useState} from "react";
import {
    Table,
    Button,
    Modal,
    Form,
    Input,
    Upload,
    message,
    Space,
    Popconfirm, Layout, Row,
} from "antd";
import Api from "../api"
import {UploadOutlined, EditOutlined, DeleteOutlined, PlusOutlined} from "@ant-design/icons";

const {Content} = Layout;

const CoursesView = () => {
    const [courses, setCourses] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingCourse, setEditingCourse] = useState(null);

    const [form] = Form.useForm();

    useEffect(() => {
        loadCourses();
    }, []);

    const loadCourses = () => {
        Api.get('/admin/courses').then(response => {
            const updatedCourses = response.data;

            setCourses(updatedCourses);

            if (editingCourse) {
                const courseToEdit = updatedCourses.find(course => course.id === editingCourse.id);

                setEditingCourse(courseToEdit)
            }
        })
    }

    // Handle adding or editing a course
    const handleSubmit = (values) => {
        const method = isEditing ? 'put' : 'post';
        const url = isEditing ? `/admin/courses/${editingCourse.id}` : '/admin/courses';

        Api[method](url, values).then(response => {
            if (isEditing) {
                setCourses(prevCourses =>
                    prevCourses.map(course => course.id === editingCourse.id ? response.data : course)
                );

                message.success("Course updated successfully!");
            } else {
                setCourses(prevCourses => [...prevCourses, response.data]);

                message.success("Course added successfully!");
            }

            form.resetFields();
            setIsModalOpen(false);
            setIsEditing(false);
            setEditingCourse(null);
        }).catch(error => {
            message.error("Failed to save course.");
        });
    };

    // Open modal for editing
    const handleEdit = (record) => {
        setEditingCourse(record);
        setIsEditing(true);
        form.setFieldsValue(record);
        setIsModalOpen(true);
    };

    // Handle delete
    const handleDelete = (id) => {
        Api.delete(`/admin/courses/${id}`).then(() => {
            setCourses(prevCourses => prevCourses.filter(course => course.id !== id));
            message.success("Course deleted successfully!");
        }).catch(() => {
            message.error("Failed to delete course.");
        });
    };

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Thumbnail",
            dataIndex: "thumbnail",
            key: "thumbnail",
            render: (thumbnail) => (
                <img src={thumbnail} alt="Thumbnail" style={{width: "80px", height: "50px", objectFit: "cover"}}/>
            ),
        },
        {
            title: "Video URL",
            dataIndex: "video",
            key: "video",
        },
        {
            title: "Time",
            dataIndex: "time",
            key: "time",
        },
        {
            title: "Tasks",
            key: "tasks",
            render: (text, record) => {
                return record && record.tasks && record.tasks.length;
            },
        },
        {
            title: "",
            key: "actions",
            render: (text, record) => (
                <Space>
                    <Button
                        icon={<EditOutlined/>}
                        onClick={() => handleEdit(record)}
                    >
                    </Button>
                    <Popconfirm
                        title="Are you sure you want to delete this course?"
                        onConfirm={() => handleDelete(record.id)}
                    >
                        <Button icon={<DeleteOutlined/>} danger>
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <Content style={{padding: "20px"}}>
            <h1>Video courses</h1>
            <Button
                type="primary"
                icon={<PlusOutlined/>}
                style={{marginBottom: "20px"}}
                onClick={() => {
                    form.resetFields();
                    setIsEditing(false);
                    setEditingCourse(null);
                    setIsModalOpen(true);
                }}
            >
                Add Course
            </Button>
            <Table
                dataSource={courses}
                columns={columns}
                rowKey="id"
                bordered
                pagination={false}
            />

            <Modal
                title={isEditing ? "Edit Course" : "Add Course"}
                visible={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                onOk={() => form.submit()}
                okText={isEditing ? "Update" : "Add"}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Form.Item
                        name="title"
                        label="Title"
                        rules={[{required: true, message: "Please enter the course title"}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="thumbnail"
                        label="Thumbnail URL"
                        rules={[{required: true, message: "Please enter the thumbnail URL"}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="video"
                        label="Video URL"
                        rules={[{required: true, message: "Please enter the video URL"}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="time"
                        label="Time"
                        rules={[{required: true, message: "Please enter the course duration"}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Description"
                        rules={[{required: true, message: "Please enter the course description"}]}
                    >
                        <Input.TextArea rows={4}/>
                    </Form.Item>

                    {editingCourse && (
                        <CourseTasks course={editingCourse} loadCourses={loadCourses}/>
                    )}
                </Form>
            </Modal>
        </Content>
    );
};

const CourseTasks = ({course, loadCourses}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingTask, setEditingTask] = useState(null);
    const [form] = Form.useForm();

    // Handle adding a new task
    const handleAddTask = (courseId, taskValues) => {
        Api.post(`/admin/courses/${courseId}/tasks`, taskValues)
            .then(() => {
                loadCourses();
                message.success("Task added successfully!");
            })
            .catch(() => {
                message.error("Failed to add task.");
            });
    };

    // Handle editing an existing task
    const handleEditTask = (courseId, taskId, updatedValues) => {
        Api.put(`/admin/courses/${courseId}/tasks/${taskId}`, updatedValues)
            .then(() => {
                loadCourses();
                message.success("Task updated successfully!");
            })
            .catch(() => {
                message.error("Failed to update task.");
            });
    };

    // Handle deleting a task
    const handleDeleteTask = (courseId, taskId) => {
        Api.delete(`/admin/courses/${courseId}/tasks/${taskId}`)
            .then(() => {
                loadCourses();
                message.success("Task deleted successfully!");
            })
            .catch(() => {
                message.error("Failed to delete task.");
            });
    };

    // Open modal for adding/editing a task
    const handleOpenModal = (task = null) => {
        if (task) {
            setIsEditing(true);
            setEditingTask(task);
            form.setFieldsValue(task);
        } else {
            setIsEditing(false);
            setEditingTask(null);
            form.resetFields();
        }
        setIsModalOpen(true);
    };

    // Handle form submit (add or edit task)
    const handleSubmit = (values) => {
        const courseId = course.id;
        if (isEditing && editingTask) {
            handleEditTask(courseId, editingTask.id, values);
        } else {
            handleAddTask(courseId, values);
        }
        setIsModalOpen(false);
    };

    const taskColumns = [
        {
            title: "Task",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "",
            key: "actions",
            width: "80px",
            render: (text, record) => (
                <Space>
                    <Button
                        size="small"
                        icon={<EditOutlined/>}
                        onClick={() => handleOpenModal(record)}
                    >

                    </Button>
                    <Popconfirm
                        title="Are you sure you want to delete this task?"
                        onConfirm={() => handleDeleteTask(course.id, record.id)}
                    >
                        <Button size="small" icon={<DeleteOutlined/>} danger>

                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Row type="flex" justify="space-between" align="middle" style={{marginBottom: "12px"}}>
                <span>Tasks</span>
            </Row>
            <Table
                size="small"
                dataSource={course.tasks}
                columns={taskColumns}
                rowKey="id"
                bordered
                pagination={false}
            />

            <Row type="flex"  justify="center" style={{marginBottom: "12px", marginTop: "12px"}}>

                <Button size="small" icon={<PlusOutlined/>} type="primary" onClick={() => handleOpenModal()}>
                    Add new task
                </Button>
            </Row>

            <Modal
                title={isEditing ? "Edit Task" : "Add Task"}
                visible={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                onOk={() => form.submit()}
                okText={isEditing ? "Update" : "Add"}
            >
                <Form form={form} layout="vertical" onFinish={handleSubmit}>
                    <Form.Item
                        name="description"
                        label="Task Description"
                        rules={[{required: true, message: "Please enter the task description"}]}
                    >
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default CoursesView;