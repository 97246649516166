import React, {useEffect, useState} from "react";
import {
    Layout,
    Breadcrumb,
    Row,
    Col,
    Input,
    Button,
    Skeleton,
    List,
    Form,
    Checkbox,
    Space,
    Select,
    message, Alert
} from 'antd';
import {useParams, Link} from 'react-router-dom';
import Api from "../api";
import TextArea from "antd/es/input/TextArea";
import ReactQuill, {Quill} from "react-quill";
import Item from "antd/lib/list/Item";
import "quill-emoji/dist/quill-emoji.css";
import * as Emoji from "quill-emoji";

const {Content} = Layout;

const AdventView = () => {
    const params = useParams();
    const [advent, setAdvent] = useState('');
    const [value, setValue] = useState('');
    const [points, setPoints] = useState('');
    const [answers, setAnswers] = useState([]);
    const [giftType, setGiftType] = useState('');
    const [form] = Form.useForm();
    const types = [
        {label: 'Show text', value: 'text'},
        {label: 'Give user points', value: 'points'},
        {label: 'Add +1 days to user account', value: 'days'},
        {label: 'Show question', value: 'question'},
    ]

    useEffect(() => {
        loadAdvent();
    }, [])

    const loadAdvent = () => {
        Api.get('/admin/advent-calendar/' + params.id).then(response => {
            setAdvent(response.data);

            setGiftType(response.data.gift_type);

            if (response.data.gift_type === 'question' && response.data.questions.length > 0) {
                setAnswers(response.data.questions[0].answers);
            }
        })
    }

    const onFinish = (values) => {
        // Update question
        if (values.question_text) {
            let type = (advent.questions && advent.questions.length > 0) ? 'put' : 'post';

            Api[type]('/admin/advent-calendar/' + advent.id + '/questions' + ((advent.questions && advent.questions.length > 0) ? `/${advent.questions[0].id}` : ''), {
                question: values.question_text
            }).then(response => {
                console.log('question updated');

                // Update answer
                if (answers.length > 0) {
                    answers.map(answer => {
                        let type = answer.id ? 'put' : 'post';

                        Api[type](`/admin/advent-calendar/${advent.id}/questions/${response.data.id}/answers${answer.id ? `/${answer.id}` : ''}`, {
                            answer: answer.answer,
                            is_correct: answer.is_correct
                        }).then(response => {
                            //console.log(response);
                            console.log('answer updated');
                        }).catch(err => {
                            console.log(err);
                        })
                    })
                }
            }).catch(err => {
                console.log(err);
            })
        }

        Api.put('/admin/advent-calendar/' + advent.id, {
            gift: values.gift,
            gift_type: values.gift_type,
            points: values.points,
        }).then(response => {
            loadAdvent();

            return message.success("Advent day updated");
        }).catch(e => {
            return message.error('Updating failed');
        });
    };

    const modules = {
            toolbar: [
                [{'header': [1, 2, false]}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link'],
                ['clean'],
                ['emoji']
            ],
            "emoji-toolbar": true,
        },

        formats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link',
            'emoji'
        ]

    const handleFormValuesChange = (changedValues) => {
        const fieldName = Object.keys(changedValues)[0];

        setPoints(fieldName)
    }

    const handleAddAnswer = () => {
        setAnswers([...answers, {answer: "", is_correct: false}]);
    };

    const handleRemoveAnswer = (index, id) => {
        if (id) {
            Api.delete(`/admin/advent-calendar/${advent.id}/questions/${advent.questions[0].id}/answers/${id}`).then(response => {
                console.log(response);
                message.success('removed');
            })
        }

        setAnswers(answers.filter((_, i) => i !== index));
    };

    const handleAnswerChange = (value, index) => {
        const updatedAnswers = [...answers];
        updatedAnswers[index].answer = value;
        setAnswers(updatedAnswers);
    };

    const handleCorrectToggle = (index) => {
        const updatedAnswers = [...answers];
        updatedAnswers[index].is_correct = !updatedAnswers[index].is_correct;
        setAnswers(updatedAnswers);
    };

    console.log(advent);

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/advent-calendar">Advent</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{params.id}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">

                <h1>Advent</h1>

                {!advent ? (
                    <Skeleton title={true} loading={true} active/>
                ) : (
                    <Row type="flex" justify="center">
                        <Col span={24} md={16}>
                            <Form
                                form={form}
                                name="advent"
                                labelCol={{span: 24}}
                                onFinish={onFinish}
                                wrapperCol={{span: 24}}
                                autoComplete="off"
                                initialValues={{
                                    gift_type: advent.gift_type,
                                    points: advent.points
                                }}
                                onValuesChange={handleFormValuesChange}
                            >

                                <Form.Item label=" " name="alert">
                                    <Alert
                                        message="Points value"
                                        description="0 = move to next level |  >0 = number of points to be given"
                                        type="warning"
                                        showIcon
                                        closable
                                    />
                                </Form.Item>

                                <Form.Item label="Type" name="gift_type">
                                    <Select onChange={setGiftType} options={types}></Select>
                                </Form.Item>


                                <Form.Item label="Points" name="points" initialValue={advent.points}>
                                    <Input type="number"></Input>
                                </Form.Item>


                                <Form.Item label="Gift body" name="gift" initialValue={advent.gift}>
                                    <ReactQuill modules={modules} formats={formats}
                                                value={advent.gift} onChange={setValue}/>
                                </Form.Item>

                                {giftType === "question" && (
                                    <div style={{marginBottom: 20}}>
                                        <Form.Item label="Question" name="question_text"
                                                   initialValue={advent.questions.length > 0 ? advent.questions[0].question : ''}>
                                            <Input placeholder="Enter question text"/>
                                        </Form.Item>

                                        <List
                                            dataSource={answers}
                                            renderItem={(answer, index) => (
                                                <List.Item>
                                                    <Input
                                                        placeholder="Answer text"
                                                        value={answer.answer}
                                                        onChange={(e) => handleAnswerChange(e.target.value, index)}
                                                        style={{width: "70%", marginRight: 8}}
                                                    />
                                                    <Checkbox
                                                        checked={answer.is_correct}
                                                        onChange={() => handleCorrectToggle(index)}
                                                    >
                                                        Correct
                                                    </Checkbox>
                                                    <Button size="small" danger={true}
                                                            onClick={() => handleRemoveAnswer(index, answer.id)}>Remove</Button>
                                                </List.Item>
                                            )}
                                        />

                                        <Row type="flex" justify="center">
                                            <Button type="dashed" onClick={handleAddAnswer} style={{marginTop: 16}}>
                                                Add Answer
                                            </Button>
                                        </Row>
                                    </div>
                                )}


                                <Form.Item>
                                    <Row type="flex" justify="center">
                                        <Button type="primary" htmlType="submit" size="large">
                                            Salvesta
                                        </Button>
                                    </Row>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                )}
            </div>
        </Content>
    );
}

export default AdventView;