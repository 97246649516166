import React, { useEffect, useState } from "react";
import { Modal, Form, Input, DatePicker, Button, message, Row, Switch } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import Api from "../api";

const { RangePicker } = DatePicker;

const CreateCompetition = ({ callback, competitionData, setCompetitionData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (competitionData) {
            setIsModalOpen(true);

            form.setFieldsValue({
                name: competitionData.name,
                image: competitionData.image,
                dates: [dayjs(competitionData.begin_date), dayjs(competitionData.end_date)], // Set start and end date
                enabled: competitionData.enabled === 1, // Convert enabled to boolean
            });
        } else {
            form.setFieldsValue({
                name: '',
                image: '',
                dates: [], // Set start and end date
                enabled: 0, // Convert enabled to boolean
            });
        }
    }, [competitionData, form]);

    const handleCancel = () => {
        setCompetitionData(null); // Reset competitionData (null to close the modal)
        setIsModalOpen(false);
        form.resetFields(); // Reset form fields when modal closes
    };

    const handleSubmit = (values) => {
        const { name, image, dates, enabled } = values;
        const [startDate, endDate] = dates;

        let competitionPayload = {
            name,
            type: "steps",
            begin_date: startDate.format("YYYY-MM-DD"),
            end_date: endDate.format("YYYY-MM-DD"),
            enabled: enabled ? 1 : 0, // Convert enabled to 1 or 0 based on the switch
        };

        if (image) {
            competitionPayload.image = image;
        }

        // If competitionData is provided, update the competition
        const request = competitionData
            ? Api.put(`/admin/competitions/${competitionData.id}`, competitionPayload) // Update
            : Api.post("/admin/competitions", competitionPayload); // Create

        request
            .then((response) => {
                callback();
                message.success(competitionData ? "Competition updated successfully!" : "Competition created successfully!");
                form.resetFields();
                setIsModalOpen(false);
            })
            .catch((error) => {
                message.error("Failed to process competition. Please try again.");
            });
    };

    return (
        <div>
            <Button type="primary" onClick={() => setIsModalOpen(true)}>
                Create Competition
            </Button>

            <Modal
                destroyOnClose={true}
                title={<div style={{ textAlign: "center" }}>{competitionData ? "Edit Competition" : "Create New Competition"}</div>}
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null} // Footer is handled by form buttons
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    initialValues={{
                        name: competitionData ? competitionData.name : "",
                        image: competitionData ? competitionData.image : "",
                        dates: competitionData ? [dayjs(competitionData.begin_date), dayjs(competitionData.end_date)] : [],
                        enabled: competitionData ? competitionData.enabled === 1 : true, // Set enabled based on competitionData
                    }}
                >
                    <Form.Item
                        name="name"
                        label="Competition Name"
                        rules={[{ required: true, message: "Please enter the competition name" }]}
                    >
                        <Input placeholder="Enter competition name" />
                    </Form.Item>

                    <Form.Item
                        name="image"
                        label="Image url"
                    >
                        <Input placeholder="Image url" />
                    </Form.Item>

                    <Form.Item
                        name="dates"
                        label="Start and End Date"
                        rules={[{ required: true, message: "Please select start and end dates" }]}
                    >
                        <RangePicker />
                    </Form.Item>

                    <Form.Item
                        name="enabled"
                        label="Enabled"
                        valuePropName="checked"
                        initialValue={true} // Default value is enabled (1)
                    >
                        <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />
                    </Form.Item>

                    <Form.Item>
                        <Row type="flex" justify="center">
                            <Button type="primary" htmlType="submit" style={{ marginRight: "10px" }}>
                                {competitionData ? "Update" : "Submit"}
                            </Button>
                            <Button onClick={handleCancel}>Cancel</Button>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default CreateCompetition;