import React, {useEffect, useState} from "react";
import {
    Layout,
    Breadcrumb,
    Row,
    Col,
    Table, Tag, Button
} from 'antd';
import Api from "../api.js";
import {Link, useParams} from "react-router-dom"
import CreateCompetition from "../components/create-competition";
import {EditOutlined} from "@ant-design/icons";

const {Content} = Layout;

const ChallengesReport = () => {
    const params = useParams();
    const [competitions, setCompetitions] = useState([]);
    const [competitionData, setCompetitionData] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        getCompetitions();
    }, [])

    const getCompetitions = () => {
        setLoading(true);

        Api.get('/admin/competitions').then(res => {
            setCompetitions(res.data);
            setLoading(false);
        })
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 60,
        },
        {
            title: 'image',
            dataIndex: 'image',
            key: 'image',
            width: 100,
            render: (text, record) => <img style={{maxWidth: "100px"}} src={text} alt=""/>
        },
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <Link to={"/challenges-report/" + record.id}>{text}</Link>,
        },
        {
            title: 'begin_date',
            dataIndex: 'begin_date',
            key: 'begin_date',
        },
        {
            title: 'end_date',
            dataIndex: 'end_date',
            key: 'end_date',
        },
        {
            title: 'Status',
            dataIndex: 'enabled',
            key: 'enabled',
            render: text => <span><Tag
                color={text === 1 ? "green" : "red"}>{text === 1 ? "active" : "inactive"}</Tag></span>
        },
        {
            title: 'action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => <Button onClick={() => setCompetitionData(record)}><EditOutlined/></Button>
        },
    ]

    console.log(competitionData);

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Reports</Breadcrumb.Item>
                <Breadcrumb.Item>Challenges</Breadcrumb.Item>
            </Breadcrumb>


            <Col span={24}>
                <div className="site-layout-card">

                    <Row type="flex" justify="space-between" style={{marginBottom: 20}}>
                        <h1 style={{margin: 0}}>Challenges</h1>

                        <CreateCompetition competitionData={competitionData} setCompetitionData={setCompetitionData} callback={getCompetitions}/>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Table loading={loading} style={{width: "100%"}} pagination={false} columns={columns}
                                   dataSource={competitions}/>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Content>
    );
}

export default ChallengesReport;